import styled from "styled-components"

export const ContentWrapper = styled.div`
  display: block;
  width: 100%;

  p{
    margin-bottom: 18px;
    color: var(--gray7);
  }

  h1{
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 28px;
    color: var(--gray8);
  }
`